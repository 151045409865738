/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@font-face {
  font-family: "Mukta";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/font/Mukta/Mukta-Regular.ttf") format("truetype");
}
html.ios {
  --ion-default-font: -apple-system, BlinkMacSystemFont, "Mukta" !important;
}

body.platform-ios.platform-cordova.platform-webview {
  text-rendering: auto !important;
}

ion-content {
  font-family: "Mukta" !important;
  background: #ffffff !important;
}

ion-refresher.refresher-native {
  z-index: 9999;
}

.ios h1 {
  letter-spacing: -2px !important;
}

.ios h2 {
  letter-spacing: -1px !important;
}

.ios h3 {
  letter-spacing: -1px !important;
}

.ios h4 {
  letter-spacing: -1px !important;
}

.ios h5 {
  letter-spacing: -1px !important;
}

.ios h6 {
  letter-spacing: -1px !important;
}

.ios b {
  letter-spacing: -1px !important;
}

.ios ion-label {
  letter-spacing: -0.3px !important;
}

.ios ion-button {
  letter-spacing: -1px !important;
}

.ios ion-content {
  letter-spacing: 0px !important;
}

.ios ion-footer {
  padding-bottom: 30px !important;
}

ion-footer {
  background-color: #ffffff !important;
}

ion-button {
  cursor: pointer !important;
}

.mousePointer {
  cursor: pointer !important;
}

.datePick input[type=date]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

ion-modal.reviewModal-cssClass {
  --height: 440px !important;
  --width: 85% !important;
  --border-radius: 8px;
  background: transparent !important;
  --backdrop-opacity: 0.5 !important;
}

ion-modal.reportUserModal-cssClass {
  --height: 340px !important;
  --width: 85% !important;
  --border-radius: 8px;
  background: transparent !important;
  --backdrop-opacity: 0.5 !important;
}

ion-modal.userTypeModal-cssClass {
  background: transparent !important;
  --backdrop-opacity: 0.5 !important;
}

ion-modal.selectBoatModal-cssClass {
  background: transparent !important;
  --backdrop-opacity: 0.5 !important;
}

@media only screen and (min-width: 720px) {
  .filterModal {
    padding: 0px 20vw;
  }
  .filterModal .modal-wrapper {
    width: 60vw;
  }
  ion-modal.reviewModal-cssClass {
    --height: 400px !important;
    --width: 25% !important;
    --border-radius: 8px;
    background: transparent !important;
    --backdrop-opacity: 0.5 !important;
  }
  ion-modal.reportUserModal-cssClass {
    --height: 340px !important;
    --width: 25% !important;
    --border-radius: 8px;
    background: transparent !important;
    --backdrop-opacity: 0.5 !important;
  }
  ion-modal.userTypeModal-cssClass {
    --height: 620px !important;
    --width: 45% !important;
    --border-radius: 8px;
    background: transparent !important;
    --backdrop-opacity: 0.5 !important;
  }
  ion-modal.selectBoatModal-cssClass {
    --height: 600px !important;
    --width: 35% !important;
    --border-radius: 8px;
    background: transparent !important;
    --backdrop-opacity: 0.5 !important;
  }
  .eyeIcon {
    position: absolute;
    top: 39px;
    right: 0% !important;
    z-index: 2;
  }
}
.eyeIcon {
  position: absolute;
  top: 39px;
  right: 0% !important;
  z-index: 2;
}

.network-alert-css .alert-wrapper .alert-head .alert-title {
  color: black;
  font-size: 0.9rem;
}
.network-alert-css .alert-wrapper .alert-button {
  font-size: 0.9rem;
  font-weight: bold !important;
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc, .gm-svpc {
  display: none;
}

.gmnoprint div {
  background: none !important;
}

.gm-fullscreen-control {
  display: none;
}

.justify_center {
  justify-content: center !important;
}

.fullWidth {
  width: 100% !important;
}

.align_center {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.alignItemsCenter {
  align-items: center;
}

.black {
  color: #000;
}

.white {
  color: #fff;
}

.grey {
  color: #949494;
}

.mTop_10 {
  margin: 10px 0px 0px 0px;
}

.m_5 {
  margin: 5px !important;
}

.m-0 {
  margin: 0px !important;
}

.mb-0 {
  margin-bottom: 0em !important;
}

.mb-1 {
  margin-bottom: 1em !important;
}

.mb-2 {
  margin-bottom: 2em !important;
}

.mb-3 {
  margin-bottom: 3em !important;
}

.mb-4 {
  margin-bottom: 4em !important;
}

.mb-5 {
  margin-bottom: 5em !important;
}

.mb-6 {
  margin-bottom: 6em !important;
}

.mb_px_6 {
  margin-bottom: 6px !important;
}

.mb-7 {
  margin-bottom: 7em !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-1 {
  margin-top: 1em !important;
}

.mt-2 {
  margin-top: 2em !important;
}

.mt-3 {
  margin-top: 3em !important;
}

.mt-4 {
  margin-top: 4em !important;
}

.mt-5 {
  margin-top: 5em !important;
}

.mt-6 {
  margin-top: 6em !important;
}

.mt-7 {
  margin-top: 7em !important;
}

.mt-12 {
  margin-top: 12em !important;
}

.mt-13 {
  margin-top: 13em !important;
}

.mt-15 {
  margin-top: 15em !important;
}

.mt-19 {
  margin-top: 19em !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.my-1 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.mx-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mlr_15 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.mtb_10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.mtb_5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.mb_10 {
  margin-bottom: 10px !important;
}

.mt_10 {
  margin-top: 10px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pb-1 {
  padding-bottom: 5px !important;
}

.pb-12 {
  padding-bottom: 12px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pt-1 {
  padding-top: 5px !important;
}

.pt-12 {
  padding-top: 12px !important;
}

.px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.p-0 {
  padding: 0px !important;
}

.p_8 {
  padding: 8px;
}

.pr-0 {
  padding-right: 0px !important;
}

.pr-1 {
  padding-right: 5px !important;
}

.pr-12 {
  padding-right: 12px !important;
}

.pt_8 {
  padding: 8px 0px 0px 0px;
}

.pl-0 {
  padding-left: 0px !important;
}

.pl-1 {
  padding-left: 5px !important;
}

.pl-12 {
  padding-left: 12px !important;
}

.pl-18 {
  padding-left: 18px !important;
}

.pl-22 {
  padding-left: 22px !important;
}

.pl-25 {
  padding-left: 25px !important;
}

.pl-31 {
  padding-left: 31px !important;
}

.px-1 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.pb-1 {
  padding-bottom: 5px !important;
}

.h-50 {
  height: 50px !important;
}

.h-45 {
  height: 45px !important;
}

.capitalize {
  text-transform: capitalize;
}

.italic {
  font-style: italic !important;
}

.text-underline {
  text-decoration: underline;
  color: var(--ion-color-primary);
}

.text-underlineSecondary {
  text-decoration: underline;
  color: var(--ion-color-secondary);
}

.text-capitalize {
  text-transform: capitalize;
}

.bold {
  font-weight: bold !important;
}

.fw500 {
  font-weight: 500 !important;
}

.fw600 {
  font-weight: 600;
}

.bg-none {
  background: transparent !important;
  --background: transparent !important;
}

.bg-primary {
  background: var(--ion-color-primary) !important;
  --background: var(--ion-color-primary) !important;
}

.bg-secondary {
  background: var(--ion-color-secondary) !important;
  --background: var(--ion-color-secondary) !important;
}

.bg_light {
  background: var(--ion-color-light) !important;
  --background: var(--ion-color-light) !important;
}

.bg-grey {
  background: #e7e7e7 !important;
  --background: #e7e7e7 !important;
}

ion-radio-group ion-item {
  --padding-start:10px;
}
ion-radio-group ion-item ion-radio {
  padding: 0px;
  margin: 0px;
}

.rounded {
  --border-radius: 12px !important;
  border-radius: 12px !important;
}

.rounded-sm {
  --border-radius: 8px !important;
  border-radius: 8px !important;
}

.no-rounded {
  border-radius: 0px !important;
  --border-radius: 0px !important;
}

.border-grey {
  border-top: 1px solid #d8d8d8;
}

.borderBottom-grey {
  border-bottom: 1px solid #d8d8d8;
}

.noBoxShadow {
  box-shadow: 0px 0px 0px 0px darkgrey !important;
}

.boxshadow {
  box-shadow: 1px 8px 20px rgba(76, 3, 184, 0.3);
}

.lightshadow {
  box-shadow: 1px 5px 20px rgba(0, 0, 0, 0.1);
}

.lightshadow2 {
  box-shadow: 1px 2px 5px 0px rgba(0, 0, 0, 0.1);
}

.shadow {
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
}

.toast {
  font-size: 18px;
}

.font-8 {
  font-size: 8px !important;
}

.font-9 {
  font-size: 9px !important;
}

.font-10 {
  font-size: 10px !important;
}

.font-11 {
  font-size: 11px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-13 {
  font-size: 13px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-15 {
  font-size: 15px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-17 {
  font-size: 17px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-20 {
  font-size: 20px !important;
  margin: 10px 0px 10px 0px !important;
}

.font-25 {
  font-size: 25px !important;
}

.font-32 {
  font-size: 32px !important;
}

#firstCapitalLetter::first-letter {
  text-transform: uppercase;
}

.text-truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

.text-truncate-200 {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modal {
  display: block !important;
}

.appTxt {
  font-size: 13px !important;
}

.d-flex {
  display: flex !important;
}

.d-block {
  display: block !important;
}

.font-16 {
  font-size: 16px !important;
}

.w-100 {
  width: 100% !important;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.errorMsg {
  font-size: 12px;
  color: rgb(252, 63, 63);
}

.alert-tappable.alert-radio {
  height: auto !important;
  contain: content !important;
}

.alert-radio-label {
  white-space: normal !important;
}

ion-accordion-group ion-accordion .ion-accordion-toggle-icon {
  display: none !important;
}

.dockBrokenSlide {
  height: 140px;
  width: -webkit-fill-available;
  display: flex;
  object-fit: cover;
  margin-right: auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.dockBrokenSlide img {
  height: auto !important;
  max-width: 10% !important;
  width: 10% !important;
  margin-bottom: 6px !important;
}
.dockBrokenSlide p {
  margin: 0px;
}

.dockBrokenDiv {
  border-radius: 5px;
  height: 140px;
  width: -webkit-fill-available;
  display: flex;
  object-fit: cover;
  margin-right: auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.dockBrokenDiv img {
  max-width: 35%;
  margin-bottom: 6px;
}
.dockBrokenDiv p {
  margin: 0px;
}

.dockBrokenList {
  border-radius: 5px;
  height: 100%;
  width: -webkit-fill-available;
  display: flex;
  object-fit: cover;
  margin-right: auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 15px;
}
.dockBrokenList img {
  height: auto !important;
}

.text_line2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}