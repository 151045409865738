/** Ionic CSS Variables **/
@import "~swiper/swiper-bundle.css";
:root {
  /** primary **/
  --ion-color-primary: #20bbba;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #20bbba;
  --ion-color-primary-tint: #20bbba;
  /** secondary **/
  --ion-color-secondary: #061F50;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #061F50;
  --ion-color-secondary-tint: #061F50;
  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;
  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;
  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;
  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;
  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;
  /** light **/
  --ion-color-light: #ffffff;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #ffffff;
  --ion-color-light-tint: #ffffff;
  /** lightGrey **/
  --ion-color-lightGrey: #F8F9FA;
  --ion-color-lightGrey-rgb: 244, 245, 248;
  --ion-color-lightGrey-contrast: #000000;
  --ion-color-lightGrey-contrast-rgb: 0, 0, 0;
  --ion-color-lightGrey-shade: #F8F9FA;
  --ion-color-lightGrey-tint: #F8F9FA;
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */
}
:root,
:root[mode=ios],
:root[mode=md] {
  --ion-font-family: "Mukta" !important;
  font-family: "Mukta" !important;
}

@font-face {
  font-family: "Mukta";
  src: url("/assets/font/Mukta/Mukta-Regular.ttf");
}
:root {
  --ion-font-family: "Mukta";
}

html.ios {
  --ion-default-font: -apple-system, BlinkMacSystemFont, "Mukta" !important;
}

body.platform-ios.platform-cordova.platform-webview {
  text-rendering: auto !important;
}

ion-header ion-toolbar {
  --background: #ffffff;
}

.logo {
  height: 40px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.logoMenu {
  height: 30px;
  display: block;
  margin-right: auto;
}

.loadingCss {
  color: #000 !important;
  --background: #ffffff !important ;
}

.sub-heading {
  text-align: center;
  color: #000;
}

.back-icon {
  font-size: 1.3rem;
  margin-top: 10px;
  margin-left: 7px;
}

ion-rating-stars ion-icon {
  opacity: 1 !important;
}

.ion-item-input {
  --padding-start: 0px !important;
  --padding-end: 0px !important;
  padding-inline-start: 0px !important;
  padding-inline-end: 0px !important;
  --inner-padding-end: 0px !important;
  margin: 0px 0px 0px 0px;
}
.ion-item-input ion-label {
  --color: #242424 !important;
  font-size: 15px !important;
  font-weight: bold;
}
.ion-item-input ion-icon {
  color: var(--ion-color-primary) !important;
}
.ion-item-input ion-input {
  --color: #000;
  border-radius: 5px;
  --padding-start: 12px !important;
  --padding-top: 8px !important;
  --padding-bottom: 8px !important;
  border: 1px solid #CDD1DB;
  --placeholder-color: #000 !important;
}
.ion-item-input ion-datetime {
  border-radius: 5px;
  --padding-start: 12px !important;
  --padding-top: 8px !important;
  --padding-bottom: 8px !important;
  border: 1px solid #CDD1DB;
  width: 100% !important;
  min-width: 100% !important;
  max-width: 100% !important;
}
.ion-item-input input {
  width: -webkit-fill-available !important;
  --color: #000;
  border-radius: 5px;
  padding: 8px 12px 12px 12px;
  border: 1px solid #d8d9df;
  --placeholder-color: #999 !important;
}
.ion-item-input ion-checkbox {
  border-radius: 5px !important;
  margin: 0px 8px 0px 0px;
  --border-radius: 5px !important;
  --border-color: #d8d9df !important;
}
.ion-item-input ion-select {
  --color: #000 !important;
  border-radius: 5px;
  --padding-start: 12px !important;
  --padding-top: 9px !important;
  --padding-bottom: 9px !important;
  padding-inline-start: 12px !important;
  border: 1px solid #d8d9df;
  --placeholder-color: #000 !important;
}
.ion-item-input ion-select ion-radio-group ion-item {
  --padding-start: 10px !important;
  --color: #000 !important;
  --border-color: var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-250, #d8d9df))) !important;
}
.ion-item-input ion-select .select-text {
  color: #000 !important;
}
.ion-item-input ion-textarea {
  --color: #000;
  border-radius: 5px;
  --padding-start: 12px !important;
  border: 1px solid #d8d9df;
  --placeholder-color: #999 !important;
}
.ion-item-input ion-checkbox {
  margin: 0px 10px 0px 0px;
  border: 1px solid #d8d9df;
  --border-radius: 5px !important;
  --border-color: #d8d9df !important;
}
.ion-item-input p {
  color: #000;
  margin: 0;
  font-size: 13px;
}
.ion-item-input a {
  text-decoration: underline;
  font-size: 13px;
}

.primary-btn {
  font-size: 16px;
  height: 45px;
  --box-shadow: 0px !important;
  text-transform: capitalize;
  margin: 0px;
  --border-radius: 8px;
  font-weight: bold;
  margin: 15px 0px 0px 0px;
}

.empty-p {
  padding-right: 20px;
  padding-left: 20px;
  text-align: center;
}

.empty-div {
  margin-left: auto;
  margin-right: auto;
}

.star-width {
  height: 19px;
  color: gold;
}
.star-width ion-icon {
  margin: 0px 1px 0px 1px !important;
  font-size: 1.1rem;
}
.star-width span {
  color: #707070;
  font-size: 0.8rem;
}